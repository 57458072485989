export default function CopyIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 5.83333H6.66659C5.93021 5.83333 5.33325 6.43028 5.33325 7.16666V13.8333C5.33325 14.5697 5.93021 15.1667 6.66659 15.1667H13.3333C14.0696 15.1667 14.6666 14.5697 14.6666 13.8333V7.16666C14.6666 6.43028 14.0696 5.83333 13.3333 5.83333Z"
        stroke="#00FFFF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66659 11.1667C1.93325 11.1667 1.33325 10.5667 1.33325 9.83333V3.16666C1.33325 2.43333 1.93325 1.83333 2.66659 1.83333H9.33325C10.0666 1.83333 10.6666 2.43333 10.6666 3.16666"
        stroke="#00FFFF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
