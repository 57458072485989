export default function BoostIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_935_12081)">
        <path
          d="M6.94934 1.18082C6.8082 1.11645 6.65488 1.08313 6.49976 1.08313C6.34463 1.08313 6.19131 1.11645 6.05017 1.18082L1.40809 3.29332C1.31197 3.33571 1.23025 3.40512 1.17288 3.49312C1.11551 3.58112 1.08496 3.6839 1.08496 3.78895C1.08496 3.894 1.11551 3.99678 1.17288 4.08478C1.23025 4.17277 1.31197 4.24219 1.40809 4.28457L6.05559 6.40249C6.19673 6.46687 6.35005 6.50018 6.50517 6.50018C6.6603 6.50018 6.81362 6.46687 6.95476 6.40249L11.6023 4.28999C11.6984 4.24761 11.7801 4.17819 11.8375 4.09019C11.8948 4.00219 11.9254 3.89941 11.9254 3.79437C11.9254 3.68932 11.8948 3.58654 11.8375 3.49854C11.7801 3.41054 11.6984 3.34112 11.6023 3.29874L6.94934 1.18082Z"
          stroke="black"
          strokeWidth="1.08333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.29398 5.14584L1.39815 6.01251C1.30509 6.05625 1.22641 6.12558 1.1713 6.21239C1.11618 6.29919 1.08691 6.39989 1.08691 6.50272C1.08691 6.60554 1.11618 6.70624 1.1713 6.79305C1.22641 6.87986 1.30509 6.94918 1.39815 6.99293L6.05648 9.11084C6.19688 9.17442 6.34923 9.20731 6.50336 9.20731C6.65748 9.20731 6.80983 9.17442 6.95023 9.11084L11.5977 6.99834C11.6939 6.95596 11.7756 6.88654 11.8329 6.79855C11.8903 6.71055 11.9209 6.60777 11.9209 6.50272C11.9209 6.39767 11.8903 6.29489 11.8329 6.20689C11.7756 6.11889 11.6939 6.04948 11.5977 6.00709L9.7019 5.14584"
          stroke="black"
          strokeWidth="1.08333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.29398 7.85416L1.39815 8.72082C1.30509 8.76457 1.22641 8.83389 1.1713 8.9207C1.11618 9.00751 1.08691 9.10821 1.08691 9.21103C1.08691 9.31386 1.11618 9.41456 1.1713 9.50136C1.22641 9.58817 1.30509 9.6575 1.39815 9.70124L6.05648 11.8192C6.19688 11.8827 6.34923 11.9156 6.50336 11.9156C6.65748 11.9156 6.80983 11.8827 6.95023 11.8192L11.5977 9.70666C11.6939 9.66427 11.7756 9.59486 11.8329 9.50686C11.8903 9.41886 11.9209 9.31608 11.9209 9.21103C11.9209 9.10598 11.8903 9.0032 11.8329 8.9152C11.7756 8.82721 11.6939 8.75779 11.5977 8.71541L9.7019 7.85416"
          stroke="black"
          strokeWidth="1.08333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_935_12081">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
