"use client";

import { XMarkIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  useCustomTokens,
  useDebounce,
  usePinnedTokens,
  usePrevious,
} from "@sushiswap/hooks";
import {
  NativeAddress,
  useBalances,
  useOtherTokenListsQuery,
  usePrices,
  useTokens,
} from "@sushiswap/react-query";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfaceModalName,
  Trace,
  TraceEvent,
} from "@sushiswap/telemetry";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconButton,
  TextField,
  classNames,
  gtagEvent,
} from "@sushiswap/ui";
import { Button, buttonIconVariants } from "@sushiswap/ui";
import { Currency } from "@sushiswap/ui";
import { List } from "@sushiswap/ui";
import { SkeletonCircle, SkeletonText } from "@sushiswap/ui";
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ChainId } from "sushi/chain";
import { DEFAULT_BASES } from "sushi/config";
import { Native, Token, Type } from "sushi/currency";
import { isAddress } from "viem";
import { useAccount } from "wagmi";
import { useTokenWithCache } from "../../hooks/tokens/useTokenWithCache";
import { TokenSelectorCurrencyList } from "./TokenSelectorCurrencyList";
import { TokenSelectorImportRow } from "./TokenSelectorImportRow";
import { useSortedTokenList } from "./hooks/useSortedTokenList";
import Boost from "./boost/boost";

interface TokenSelectorProps {
  id: string;
  selected: Type | undefined;
  chainId: ChainId;
  onSelect(currency: Type): void;
  children: ReactNode;
  currencies?: Record<string, Token>;
  includeNative?: boolean;
  hidePinnedTokens?: boolean;
  hideSearch?: boolean;
}

export const TokenSelector: FC<TokenSelectorProps> = ({
  includeNative = true,
  id,
  selected,
  onSelect,
  chainId,
  children,
  currencies,
  hidePinnedTokens,
  hideSearch,
}) => {
  const { address } = useAccount();

  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const previousOpen = usePrevious(open);

  const debouncedQuery = useDebounce(query, 250);

  // Clear search query when modal closes
  useEffect(() => {
    if (previousOpen && !open) {
      setQuery("");
    }
  }, [open, previousOpen]);

  useEffect(() => {
    if (debouncedQuery) gtagEvent("token-search", { query: debouncedQuery });
  }, [debouncedQuery]);

  const { data: customTokenMap, mutate: customTokenMutate } = useCustomTokens();

  const { data: defaultTokenMap, isLoading: isTokensLoading } = useTokens({
    chainId,
  });
  const { data: otherTokenMap, isLoading: isOtherTokensLoading } =
    useOtherTokenListsQuery({ chainId, query: debouncedQuery });
  const { data: pricesMap } = usePrices({ chainId });
  const {
    data: balancesMap,
    isInitialLoading: isBalanceLoading,
    refetch,
  } = useBalances({ chainId, account: address, enabled: open });

  const tokenMap = useMemo(() => {
    return {
      ...defaultTokenMap,
      ...otherTokenMap,
    };
  }, [defaultTokenMap, otherTokenMap]);

  const officialTokenIds = useMemo(() => {
    if (!defaultTokenMap) return [];
    return Object.values(defaultTokenMap).map((el) => el.wrapped.address);
  }, [defaultTokenMap]);

  const { data: queryToken, isInitialLoading: isQueryTokenLoading } =
    useTokenWithCache({
      chainId,
      address: query,
      withStatus: false,
      enabled: isAddress(query),
      keepPreviousData: false,
    });

  const { data: sortedTokenList } = useSortedTokenList({
    query: debouncedQuery,
    customTokenMap: currencies ? {} : customTokenMap,
    tokenMap: currencies ? currencies : tokenMap,
    pricesMap,
    balancesMap: balancesMap ?? {},
    chainId,
    includeNative,
  });

  // const pinnedTokens = useMemo(() => {
  //   const pinned = (pinnedTokenMap?.[chainId] ?? [])
  //     .map((id) => {
  //       const [, address] = id.split(":");
  //       if (address === "NATIVE") return Native.onChain(chainId);
  //       return tokenMap?.[address] || customTokenMap?.[address];
  //     })
  //     .filter((token): token is Token => !!token)
  //     .map((token) => ({
  //       token,
  //       isDefault: false,
  //     }));

  //   const defaults = (DEFAULT_BASES[chainId] ?? []).map((token) => ({
  //     token: token,
  //     isDefault: true,
  //   }));

  //   return [...defaults, ...pinned];
  // }, [pinnedTokenMap, tokenMap, chainId, customTokenMap]);

  const _onSelect = useCallback(
    (currency: Type) => {
      if (onSelect) {
        onSelect(currency);
        setQuery("");
      }

      setOpen(false);
    },
    [onSelect]
  );

  // const _onPin = useCallback(
  //   (currencyId: string) => {
  //     if (isTokenPinned(currencyId)) {
  //       pinnedTokenMutate("remove", currencyId);
  //     } else {
  //       pinnedTokenMutate("add", currencyId);
  //     }
  //   },
  //   [pinnedTokenMutate, isTokenPinned]
  // );

  const handleImport = useCallback(
    (currency: Token) => {
      customTokenMutate("add", [currency]);
      _onSelect(currency);
    },
    [_onSelect, customTokenMutate]
  );

  // Refetch whenever TokenSelector opens
  useEffect(() => {
    if (open && !!address) refetch();
  }, [open, address, refetch]);

  const isLoading =
    isTokensLoading || isOtherTokensLoading || isQueryTokenLoading;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="!flex flex-col justify-start min-h-[85vh] pr-0">
        <Trace
          name={InterfaceEventName.TOKEN_SELECTOR_OPENED}
          modal={InterfaceModalName.TOKEN_SELECTOR}
          shouldLogImpression
        >
          {!hideSearch ? (
            <div className="flex flex-col gap-2 pr-4">
              <div className="text-sm font-semibold pb-2 mt-[7px]">
                Select a token
              </div>
              <TextField
                variant="outline"
                className="outline-none"
                placeholder="Search name or paste address"
                icon={MagnifyingGlassIcon}
                type="text"
                testdata-id={`${id}-address-input`}
                value={query}
                onValueChange={setQuery}
              />
            </div>
          ) : null}

          <div className="text-sm font-semibold">Boosted Tokens</div>
          <div
            data-state={isLoading ? "active" : "inactive"}
            className={classNames(
              "data-[state=active]:block data-[state=inactive]:hidden",
              "py-0.5 h-[64px] -mb-3 pr-4"
            )}
          >
            <div className="flex items-center w-full h-full px-3 rounded-lg">
              <div className="flex items-center justify-between flex-grow gap-2 rounded">
                <div className="flex flex-row items-center flex-grow gap-4">
                  <SkeletonCircle radius={40} />
                  <div className="flex flex-col items-start">
                    <SkeletonText className="w-[100px]" />
                    <SkeletonText fontSize="sm" className="w-[60px]" />
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <SkeletonText className="w-[80px]" />
                  <SkeletonText
                    fontSize="sm"
                    align="right"
                    className="w-[40px]"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Boosted Token Badges */}
          {sortedTokenList && sortedTokenList.length > 0 ? (
            <div className="flex flex-wrap gap-2 pr-4">
              {sortedTokenList
                .filter(
                  (t) =>
                    (t.incetivizationPoints && t.incetivizationPoints > 0) ||
                    t.isNative
                )
                .map((token) => (
                  <TraceEvent
                    events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
                    name={InterfaceEventName.TOKEN_SELECTED}
                    properties={{
                      token_symbol: token?.symbol,
                      token_address: token?.isNative
                        ? NativeAddress
                        : token?.address,
                      total_balances_usd:
                        balancesMap?.[
                          token?.isNative ? NativeAddress : token?.address
                        ]?.quotient,
                    }}
                    element={InterfaceElementName.COMMON_BASES_CURRENCY_BUTTON}
                    key={token.id}
                  >
                    <div>
                      <Button
                        size="sm"
                        variant="secondary"
                        className="flex items-center text-[18px] !pl-[4px] !pr-[4px] h-[44px] !bg-black border-2 cursor-pointer hover:opacity-80 transition-all duration-100 hover:scale-105 border-white/20 !rounded-full"
                        key={token.id}
                        onClick={() => _onSelect(token)}
                      >
                        <div className="flex items-center">
                          <Currency.Icon
                            width={28}
                            height={28}
                            className={buttonIconVariants({ size: "default" })}
                            currency={token}
                            disableLink
                          />
                          <span className="ml-1">{token.symbol}</span>
                        </div>

                        {!!token.incetivizationPoints && (
                          <div className="ml-auto">
                            <Boost amount={token.incetivizationPoints} />
                          </div>
                        )}
                        {/* {!isDefault && (
                        <></>
                        // <IconButton
                        //   size="xs"
                        //   name="remove"
                        //   icon={XMarkIcon}
                        //   variant="ghost"
                        //   onClick={(e) => {
                        //     e.stopPropagation()
                        //     _onPin(token.id)
                        //   }}
                        // />
                      )} */}
                      </Button>
                    </div>
                  </TraceEvent>
                ))}
            </div>
          ) : null}

          <div className="text-sm font-semibold ">Popular Tokens</div>
          <List.Control className="relative !bg-transparent dark:!bg-transparent !border-none flex flex-1 flex-col flex-grow gap-3 px-1 py-0.5 min-h-[128px]">
            <div
              data-state={isLoading ? "active" : "inactive"}
              className={classNames(
                "data-[state=active]:block data-[state=active]:flex-1 data-[state=inactive]:hidden",
                "py-0.5 h-[64px] -mb-3"
              )}
            >
              <div className="flex items-center w-full h-full px-3 rounded-lg">
                <div className="flex items-center justify-between flex-grow gap-2 rounded">
                  <div className="flex flex-row items-center flex-grow gap-4">
                    <SkeletonCircle radius={40} />
                    <div className="flex flex-col items-start">
                      <SkeletonText className="w-[100px]" />
                      <SkeletonText fontSize="sm" className="w-[60px]" />
                    </div>
                  </div>

                  <div className="flex flex-col w-full">
                    <SkeletonText className="w-[80px]" />
                    <SkeletonText
                      fontSize="sm"
                      align="right"
                      className="w-[40px]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              data-state={isLoading ? "inactive" : "active"}
              className={classNames(
                "data-[state=active]:block data-[state=active]:flex-1 data-[state=inactive]:hidden"
              )}
            >
              {queryToken &&
                !customTokenMap[
                  `${queryToken.chainId}:${queryToken.wrapped.address}`
                ] &&
                !tokenMap?.[`${queryToken.wrapped.address}`] && (
                  <TokenSelectorImportRow
                    currency={queryToken}
                    onImport={() => queryToken && handleImport(queryToken)}
                  />
                )}
              <TokenSelectorCurrencyList
                selected={selected}
                onSelect={_onSelect}
                id={id}
                // pin={{ onPin: _onPin, isPinned: isTokenPinned }}
                officialTokenIds={officialTokenIds}
                currencies={sortedTokenList}
                chainId={chainId}
                balancesMap={balancesMap ?? {}}
                pricesMap={pricesMap}
                isBalanceLoading={isBalanceLoading}
              />
              {sortedTokenList?.length === 0 && !queryToken && chainId && (
                <span className="h-10 flex items-center justify-center text-center text-sm text-gray-500 dark:text-slate-500">
                  No results found.
                </span>
              )}
            </div>
          </List.Control>
        </Trace>
      </DialogContent>
    </Dialog>
  );
};
