import { classNames } from "@sushiswap/ui";
import { SkeletonText } from "@sushiswap/ui";
import { FC, useMemo } from "react";
import {
  warningSeverity,
  warningSeverityClassName,
} from "src/lib/swap/warningSeverity";
import { tryParseAmount } from "sushi/currency";
import { Fraction, ZERO } from "sushi/math";
import { CurrencyInputProps } from "./CurrencyInput";

type PricePanel = Pick<
  CurrencyInputProps,
  "loading" | "currency" | "value" | "priceImpact"
> & {
  error?: string;
  price: Fraction | undefined;
};

export const PricePanel: FC<PricePanel> = ({
  loading,
  price,
  currency,
  value,
  priceImpact,
  error,
}) => {
  const parsedValue = useMemo(
    () => tryParseAmount(value, currency),
    [currency, value]
  );
  const [big, portion] = (
    parsedValue && price
      ? `${price.asFraction
          .multiply(parsedValue)
          .divide(10 ** parsedValue.currency.decimals)
          .toFixed(2)}`
      : "0.00"
  ).split(".");

  if (loading)
    return (
      <div className="w-1/5 flex items-center">
        <SkeletonText fontSize="lg" className="w-full" />
      </div>
    );

  if (error) {
    return (
      <p className="font-medium text-lg py-1 select-none text-red">{error}</p>
    );
  }

  return (
    <p className="font-medium text-[12px] flex items-baseline select-none text-white/25">
      {!loading && price?.equalTo(ZERO) ? (
        <span className="flex items-center">Price not available</span>
      ) : (
        <>
          ${big}.{portion}
        </>
      )}
      {!(!loading && price?.equalTo(ZERO)) && priceImpact && (
        <span
          className={classNames(
            "text-sm pl-1",
            warningSeverityClassName(warningSeverity(priceImpact))
          )}
        >
          {priceImpact?.lessThan(ZERO)
            ? "+"
            : priceImpact?.greaterThan(ZERO)
            ? "-"
            : ""}
          {Math.abs(Number(priceImpact?.toFixed(2)) || 0)}%
        </span>
      )}
    </p>
  );
};
