import { ClipboardController, Currency, List } from "@sushiswap/ui";
import { shortenAddress } from "sushi";
import { Token } from "sushi/currency";
import ExternalLinkIcon from "./simple/external-link-icon";
import CopyIcon from "./simple/copy-icon";

type TokenCardProps = {
  title?: string;
  token: Token;
  url: string;
};

export default function TokenCard({ title, token, url }: TokenCardProps) {
  return (
    <List className="min-w-[180px] overflow-visible">
      <div className="text-white text-sm font-semibold text-center">
        {title}
      </div>

      <List.Control className="text-center p-2 flex flex-col overflow-visible">
        <div className="mx-auto py-2">
          <Currency.Icon width={60} height={60} currency={token} disableLink />
        </div>
        <div className="text-[18px] font-semibold">{token.name}</div>
        <div className="text-sm font-semibold text-white/50 mb-1">
          {token.symbol}
        </div>
        <div className="bg-white/10 rounded-md py-1 px-2 flex mx-auto gap-2">
          <a
            target="_blank"
            href={url}
            className="text-bhCyan text-sm"
            rel="noreferrer"
          >
            {shortenAddress(token.address)}
          </a>
          <div className="flex gap-1 z-30">
            <a href={url} target="_blank" rel="noreferrer">
              <ExternalLinkIcon />
            </a>
            <ClipboardController>
              {({ setCopied, isCopied }) => (
                <span
                  className="cursor-pointer relative z-40"
                  onClick={() => setCopied(token.address)}
                >
                  <CopyIcon />
                </span>
              )}
            </ClipboardController>
          </div>
        </div>
      </List.Control>
    </List>
  );
}
