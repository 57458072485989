import { useTokenSecurity } from "@sushiswap/react-query";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  TraceEvent,
} from "@sushiswap/telemetry";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Message,
} from "@sushiswap/ui";
import { Button } from "@sushiswap/ui";
import { Currency } from "@sushiswap/ui";
import { List } from "@sushiswap/ui";
import { FC, useCallback, useMemo, useState } from "react";
import { Chain } from "sushi/chain";
import { Token } from "sushi/currency";
import { shortenAddress } from "sushi/format";

import { isTokenSecurityChainId } from "sushi/config";
import { TokenSecurityView } from "../token-security-view";
import TokenCard from "src/ui/swap/token-card";
import { useDerivedStateSimpleSwap } from "src/ui/swap/simple/derivedstate-simple-swap-provider";
import { useChainId } from "wagmi";

interface TokenSelectorImportRow {
  currency: Token;
  onImport(): void;
}

export const TokenSelectorImportRow: FC<TokenSelectorImportRow> = ({
  currency,
  onImport,
}) => {
  const [open, setOpen] = useState(false);

  const chainId = useChainId();

  const {
    data: tokenSecurityResponse,
    isInitialLoading: tokenSecurityLoading,
  } = useTokenSecurity({
    currencies: [currency],
    enabled: open,
  });

  const onClick = useCallback(() => {
    onImport();

    setTimeout(() => {
      setOpen(false);
    }, 250);
  }, [onImport]);

  const honeypot = Boolean(
    currency && tokenSecurityResponse?.[currency.address]?.is_honeypot
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <div className="relative py-0.5 h-[64px]">
        <div className="flex items-center w-full hover:bg-muted focus:bg-accent h-full rounded-lg px-3">
          <div className="flex flex-row items-center flex-grow gap-4">
            <div className="w-10 h-10">
              <Currency.Icon
                disableLink
                currency={currency}
                width={40}
                height={40}
              />
            </div>
            <div className="flex flex-col items-start">
              <span className="font-semibold text-gray-900 group-hover:text-gray-900 dark:text-slate-50 dark:group-hover:text-white">
                {currency.symbol}
              </span>
              <span className="text-sm text-gray-500 dark:text-slate-400 group-hover:dark:text-bhCyan-100">
                {currency.name}
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <Button onClick={() => setOpen(true)} size="xs">
              Import
            </Button>
          </div>
        </div>
      </div>

      <DialogContent
        hideClose={true}
        className="bg-transparent border-none !gap-0"
      >
        <div className="!max-h-screen bg-bhDark overflow-y-auto border border-white/20 rounded-lg p-4">
          <DialogHeader>
            <DialogTitle>Import token</DialogTitle>
            <DialogDescription>
              Anyone can create a token, including creating fake versions of
              existing tokens that claim to represent projects. If you purchase
              this token, you may not be able to sell it back.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center pb-5 flex-col gap-4">
            <TokenCard
              title="Token"
              token={currency}
              url={Chain.from(chainId)?.getTokenUrl(currency.address)}
            />
            {isTokenSecurityChainId(currency.chainId) ? (
              <TokenSecurityView
                tokenSecurityResponse={tokenSecurityResponse}
                token={currency}
              />
            ) : null}
          </div>
          <DialogFooter>
            <div className="flex flex-col gap-3 w-full">
              <TraceEvent
                events={[BrowserEvent.onClick, BrowserEvent.onKeyPress]}
                name={InterfaceEventName.TOKEN_IMPORTED}
                properties={{
                  token_symbol: currency?.symbol,
                  token_address: currency?.address,
                }}
                element={InterfaceElementName.IMPORT_TOKEN_BUTTON}
              >
                <Button
                  fullWidth
                  size="xl"
                  className="cursor-pointer hover:opacity-80"
                  onClick={onClick}
                >
                  I understand
                </Button>
              </TraceEvent>
            </div>
          </DialogFooter>
        </div>

        <Button
          className="text-white/40 pt-0 cursor-pointer hover:text-white"
          variant="ghost"
          fullWidth
          size="xl"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
