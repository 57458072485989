import BoostIcon from "./boost-icon";
import boostImg from "./boost.png";
export default function Boost({ amount }: { amount: number }) {
  return (
    <div className="relative bg-white rounded-full h-[30px] px-2 flex items-center">
      <div className="absolute top-0 left-0 w-full h-full rounded-full overflow-hidden">
        <div className="hero"></div>
      </div>

      <div className="mr-1 z-10">
        <BoostIcon />
      </div>
      <div className="text-black text-xs font-bold z-10">{amount}x</div>
    </div>
  );
}
