import { Button, SkeletonText, classNames } from "@sushiswap/ui";
import { WalletIcon } from "@sushiswap/ui/icons/WalletIcon";
import { FC, memo, useCallback } from "react";
import { Amount, Native, Type } from "sushi/currency";

import { useIsMounted } from "@sushiswap/hooks";
import { CurrencyInputProps } from "./CurrencyInput";

type BalancePanel = Pick<
  CurrencyInputProps,
  "chainId" | "onChange" | "currency" | "disableMaxButton" | "loading"
> & {
  id?: string;
  account: string | undefined;
  balance: Amount<Type> | null | undefined;
  type: "INPUT" | "OUTPUT";
};

const MIN_NATIVE_CURRENCY_FOR_GAS = 10n ** 16n; // .01 ETH

const formatBalance = (balance: string) => {
  const num = parseFloat(balance);
  if (num >= 1e9) return (num / 1e9).toFixed(1) + "B";
  if (num >= 1e6) return (num / 1e6).toFixed(1) + "M";
  if (num >= 1e3) return (num / 1e3).toFixed(1) + "k";
  return num.toFixed(2);
};

export const BalancePanel: FC<BalancePanel> = memo(function BalancePanel({
  id,
  balance,
  onChange,
  loading,
  type,
}) {
  const isMounted = useIsMounted();

  const formattedBalance = balance
    ? formatBalance(balance.toSignificant(6))
    : "0.00";

  const onMaxClick = useCallback(() => {
    if (onChange && balance?.greaterThan(0)) {
      if (
        balance.currency.isNative &&
        balance.greaterThan(MIN_NATIVE_CURRENCY_FOR_GAS)
      ) {
        const hundred = Amount.fromRawAmount(
          Native.onChain(balance.currency.chainId),
          MIN_NATIVE_CURRENCY_FOR_GAS
        );
        onChange(balance.subtract(hundred).toFixed());
      } else {
        onChange(balance?.greaterThan(0) ? balance.toFixed() : "");
      }
    }
  }, [balance, onChange]);

  if (loading || !isMounted) {
    return (
      <div className="w-[60px] flex items-center">
        <SkeletonText fontSize="lg" className="w-full" />
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <p className="text-xs text-white/50 font-medium">Balance:</p>
      <span className="text-xs text-white/50 font-medium pl-1">
        {formattedBalance}
      </span>
      <Button
        id={`${id}-balance-button`}
        onClick={onMaxClick}
        variant="ghost"
        size="xs"
        className="text-xs mb-[2px] text-white"
      >
        Max
      </Button>
    </div>
  );
});
